import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import classes from "./Footer.module.css";
import rival from "../../asset/rival-text.png";
import InternationalBtn from "../international-btn/InternationalBtn";
import { MyContext } from "../../Context/AuthContext";
import { QRCode } from "react-qrcode-logo";
import RivalLogo from "../../asset/footerLogo.png";
import QRcode from "../../asset/scannerQR.png";

import qrcode from "../../asset/qr-code.jpeg";

const Footer = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  // const handleClick = (pageId, route) => {
  //   navigate(route); // Navigate to the desired page

  //   // scrollToTop(); // Scroll to the top
  //   // navigate(route ? route : `/?page_id=${pageId}`); // Navigate to the desired page
  // };



  const handleClick = (pageId, route) => {
    navigate(route, { replace: true }); // Prevents adding the route to history stack, no scroll triggered
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes("savings55")) {
      // Prevent scrolling for specific routes
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const { handlePopoverClose } = useContext(MyContext);

  return (
    <footer onMouseEnter={handlePopoverClose}>
      <div className={classes.footer_main}>
        <div className={classes.footer_left}>
          <p className={classes.footer_title}>Empower your money</p>
          <InternationalBtn
            title="Get The App"
            style={{
              maxWidth: "160px",
              padding: "15px 0px",
              marginBottom: "2.2rem",
            }}
          />
          <img
            onClick={() => handleClick(null, "/")}
            src={rival}
            alt="rival"
            style={{ width: "80px", height: "auto", marginTop: "30px" }}
          />
        </div>
        <div className={classes.footer_right}>
          {/* <div className={classes.qr_code}>
            <div className={classes.qr_code_right}>
              <div style={{ position: "relative", display: "inline-block" }}>
                {/* QR Code */}
          {/* <QRCode
                  value="https://apps.apple.com/lb/app/rival-finance/id6468575208"
                  size={200}
                  style={{ width: "100%", height: "auto" }}
                /> */}

          {/* Centered Image */}
          {/* <img
                  src={RivalLogo}
                  alt="Rival Logo"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "20px", // Adjust size as needed
                    height: "20px", // Adjust size as needed
                    borderRadius: "50%", // Optional: makes the logo circular
                  }}
                />
              </div>
            </div>
            <div className={classes.qr_code_left}>
              <p>Download</p>
              <h5>Rival.Finance</h5>
            </div>
          </div>  */}
          <div className={classes.qr_code}>
            <img src={QRcode} alt="logo" width="100%" height="100px" />

          </div>
          <div className={classes.footer_content}>
            <div className={classes.footer_content_section}>
              <h4>Personal</h4>
              <ul>
                <li onClick={() => handleClick(null, "/Personal")}>Personal</li>
                <li onClick={() => handleClick(null, "/cards")}>Cards</li>
                <li onClick={() => handleClick(null, "/transfer")}>
                  Transfers
                </li>
                <li onClick={() => handleClick(null, "/compare")}>
                  Compare Plans
                </li>
                <li onClick={() => handleClick(null, "/investing")}>
                  Investing
                </li>
              </ul>
            </div>
            <div className={classes.footer_content_section}>
              <h4>Crypto</h4>
              <ul>
                <li onClick={() => handleClick(null, "/crypto")}>Crypto</li>

              </ul>
            </div>
            <div className={classes.footer_content_section}>
              <h4>Company</h4>
              <ul>
                <li onClick={() => handleClick(null, "/aboutUs")}>About us</li>
                <li onClick={() => handleClick(null, "/help&support")}>
                  Customer support
                </li>
                <li onClick={() => handleClick(null, "/terms&conditions")}>
                  Terms & Conditions
                </li>
                <li onClick={() => handleClick(null, "/privacy-policy")}>Privacy Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer_social_icons}>
        <div className={classes.footer_icon_container}>
          <a
            href="https://www.linkedin.com/company/rival-financial/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.footer_icon}
          >
            <FaLinkedinIn />
          </a>
        </div>

        <div className={classes.footer_icon_container}>

          <a
            href="https://www.instagram.com/rival.finance?igsh=MXcyZXltMXZyc203NA=="
            target="_blank"
            rel="noopener noreferrer"
            className={classes.footer_icon}
          >
            <FaInstagram />
          </a>
        </div>
        {/* <div className={classes.footer_icon_container}>
          <FaFacebookF className={classes.footer_icon} />
        </div>
        <div className={classes.footer_icon_container}>
          <FaXTwitter className={classes.footer_icon} />
        </div> */}
      </div>
      <hr />
      <p className={classes.footer_para}>
        Copyright © {new Date().getFullYear()} Rival. All rights reserved.
      </p>
      <p className={classes.footer_para}>
        Rival Payment Services Provider Co. L.L.C., operating under the name
        Rival Finance, is registered in the United Arab Emirates, established by
        the Dubai Ministry of Economy and Trade (License No. 1308095).
        Specializing in card issuance and acquiring services, Rival Finance is
        dedicated to facilitating comprehensive card programs, both on a local
        and international scale, in strict adherence to regulatory standards and
        professional excellence.
      </p>
      {/* <p className={classes.footer_para}>
        Beirut Brokerage Corporation, serving as the designated investing broker
        for Rival Finance, is duly licensed in Lebanon by the Capital Markets
        Authority (License No. 8). Located in Verdun, Beirut, the corporation
        specializes in providing premium investing services, ensuring compliance
        with the highest regulatory standards and professional practices within
        the financial industry.
      </p> */}
    </footer>
  );
};

export default Footer;
