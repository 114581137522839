import React from "react";

const GoogleDocsPDFPreview = () => {
  // Replace this with your PDF URL
  const pdfUrl = "https://forbackend.s3.amazonaws.com/uploads/17146653786421714665378294_disclaimers-merged.pdf";

  return (
    <div style={{ padding: "8rem 20px", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <h2 style={{ fontSize: "40px", marginBottom: "30px" }}>Partner Terms</h2>
      <h5 style={{ fontSize: "22px", color: "#86868B", widht: "100%", maxWidth: "700px", textAlign: "center" }}>The Terms were updated on 23 August 2024 and apply to all new Rival Personal customers who opened their account on or after 23 August 2024.</h5>
      <div style={{ marginTop: "20px", height: "600px", border: "1px solid #ccc", width: "100%", maxWidth: "800px" }}>
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
          title="PDF Preview"
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleDocsPDFPreview;
