import React, { useContext } from 'react';
import { MyContext } from '../../Context/AuthContext';

import CryptoBanner from '../../build-component/crypto-section/crypto-banner/CryptoBanner';
import CryptoInfo from '../../build-component/crypto-section/crypto-info/CryptoInfo';
import CryptoSections from '../../build-component/crypto-section/crypto-sections/CryptoSections';
import MoneyAbroad from '../../build-component/crypto-section/money-abroad/MoneyAbroad';
import CryptoInstantly from '../../build-component/crypto-section/crypto-instantly/CryptoInstantly';
import CryptoTexts from '../../build-component/crypto-section/crypto-texts/CryptoTexts';
import SimpleAnimation from '../../components/simple-animation/SimpleAnimation';
import CryptoToggle from '../../components/crypto-toggle/CryptoToggle';
import Component26 from '../../build-component/component26/Component26';

import coins from '../../asset-video/crypto-new-coin.mp4';

const Crypto = () => {
  const {
    handlePopoverClose,
  } = useContext(MyContext);
  return (
    <div onMouseEnter={handlePopoverClose}>
      <CryptoBanner />
      <CryptoInfo />
      <CryptoTexts />
      <SimpleAnimation data={coins} style={{ marginBottom: "-5px" }} />
      {/* <SimpleAnimation data={"https://res.cloudinary.com/dwevyyuwo/video/upload/v1736244811/Test/nuilwgft4yeih9glc5oa.mp4"} style={{ marginBottom: "-5px" }} /> */}
      <MoneyAbroad />
      <Component26 />
      <CryptoSections />
      <CryptoToggle />
    </div>
  )
}

export default Crypto;