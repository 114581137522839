import React, { useState, useEffect, useRef } from 'react';
import classes from './PrivacySecurityChild.module.css';

const PrivacySecurityChild = ({ desc1, desc2, desc3, img1, img2, img3 }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        // Detect screen size
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        handleResize(); // Check screen size on initial load
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isSmallScreen) {
            // Disable observer on small screens
            setIsVisible(true);
            return;
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 } // Adjust the threshold as needed
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [isSmallScreen]);

    return (
        <div className={classes.privacy_child_parent}>
            {[{ img: img1, desc: desc1 }, { img: img2, desc: desc2 }, { img: img3, desc: desc3 }].map((item, index) => (
                <div
                    key={index}
                    ref={containerRef}
                    style={{
                        position: 'relative',
                        transform: isVisible || isSmallScreen ? 'translateY(0)' : 'translateY(20px)',
                        opacity: isVisible || isSmallScreen ? 1 : 0,
                        transition: isSmallScreen ? 'none' : 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
                    }}
                    className={classes.privacy_child_child}>
                    <img src={item.img} alt={`img-${index}`} style={{ width: "50px", height: "50px", marginBottom: "1rem", objectFit: 'contain' }} />
                    <p dangerouslySetInnerHTML={{ __html: item.desc }} />
                </div>
            ))}
        </div>
    );
};

export default PrivacySecurityChild;





// import React, { useState, useEffect, useRef } from 'react';
// import classes from './PrivacySecurityChild.module.css';

// const PrivacySecurityChild = ({ desc1, desc2, desc3, img1, img2, img3 }) => {
//     const [isVisible, setIsVisible] = useState(false);
//     const containerRef = useRef(null);

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 if (entry.isIntersecting) {
//                     setIsVisible(true);
//                 }
//             },
//             { threshold: 0.5 } // Adjust the threshold as needed
//         );

//         if (containerRef.current) {
//             observer.observe(containerRef.current);
//         }

//         return () => {
//             if (containerRef.current) {
//                 observer.unobserve(containerRef.current);
//             }
//         };
//     }, []);
//     return (
//         <div className={classes.privacy_child_parent}>
//             <div
//                 ref={containerRef}
//                 style={{
//                     position: 'relative',
//                     transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//                     opacity: isVisible ? 1 : 0,
//                     transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//                 }}
//                 className={classes.privacy_child_child}>
//                 <img src={img1} alt="img" style={{ width: "50px", height: "50px", marginBottom: "1rem",objectFit:'contain' }} />

//                 <p dangerouslySetInnerHTML={{ __html: desc1 }} />
//             </div>
//             <div
//                 ref={containerRef}
//                 style={{
//                     position: 'relative',
//                     transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//                     opacity: isVisible ? 1 : 0,
//                     transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//                 }}
//                 className={classes.privacy_child_child}>
//                 <img src={img2} alt="react" style={{ width: "50px", height: "50px", marginBottom: "1rem",objectFit:'contain' }} />
//                 <p dangerouslySetInnerHTML={{ __html: desc2 }} />
//             </div>
//             <div

//                 ref={containerRef}
//                 style={{
//                     position: 'relative',
//                     transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
//                     opacity: isVisible ? 1 : 0,
//                     transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
//                 }} className={classes.privacy_child_child}>
//                 <img src={img3} alt="react" style={{ width: "50px", height: "50px", marginBottom: "1rem",objectFit:'contain' }} />
//                 <p dangerouslySetInnerHTML={{ __html: desc3 }} />
//             </div>
//         </div>
//     )
// }

// export default PrivacySecurityChild;


