




// import React, { useState, useEffect } from 'react';
// import classes from './Component9.module.css';
// import { FaChevronRight } from 'react-icons/fa';
// import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
// import halfcircle from '../../asset/halfCircle.png';
// import pluscircle from '../../asset/plus-circle.png';
// import BestModal from '../../components/rival-best/best-modal/BestModal';
// import BestFirst from '../../components/rival-best/best-first/BestFirst';

// import ni1 from '../../asset/ni1.png';
// import ni2 from '../../asset/ni2.png';
// import ni3 from '../../asset/ni3.png';
// import ni4 from '../../asset/ni4.png';
// import ni5 from '../../asset/ni5.png';


// const Component9 = () => {

//   const [scrollPosition, setScrollPosition] = useState(0);
//   const [activeModal, setActiveModal] = useState(null); // Track active modal

//   const carouselRef = React.useRef(null);

//   const scrollLeft = () => {
//     const newPosition = Math.max(scrollPosition - 420, 0);
//     setScrollPosition(newPosition);
//     carouselRef.current.scrollTo({
//       left: newPosition,
//       behavior: 'smooth',
//     });
//   };

//   const scrollRight = () => {
//     const newPosition = scrollPosition + 420;
//     setScrollPosition(newPosition);
//     carouselRef.current.scrollTo({
//       left: newPosition,
//       behavior: 'smooth',
//     });
//   };

//   const handleCardClick = (component) => {
//     setActiveModal(component);
//   };

//   const closeModal = () => {
//     setActiveModal(null);
//   };

//   return (
//     <div className={classes.financeapplication_parent}>
//       <div className={classes.financeapplication_top}>
//         <h2>Why rival is the best finance apps</h2>
//         <div className={classes.financeapplication_top_right}>
//           <a href="">Learn more</a>
//           <FaChevronRight size={12} color="#246BFD" />
//         </div>
//       </div>
//       <div className={classes.carousel} ref={carouselRef}>
//         <div
//           className={classes.card}
//           onClick={() => handleCardClick(<BestFirst
//             title1="Payments"
//             title2="Make every payment seamless and secure."
//             desc1="At Rival, we believe payments should be simple and stress-free. Whether you're paying for your 
//             morning coffee or an international service, our platform ensures every transaction is instant and 
//             hassle-free. With no hidden fees and seamless integration of fiat and crypto options, Rival empowers 
//             you to make payments confidently, knowing you're in full control."
//             desc2="Pay online, in-store, or even through subscription services with the same level of ease and security."
//           />)}
//         >
//           <img className={classes.img1} src={ni1} alt="Component A" />
//           <h3>Make every payment seamless and secure.</h3>
//           <p>At Rival, we believe payments should be simple and stress-free. Whether you're paying for your morning coffee or an international service...</p>
//           <img className={classes.img2} src={pluscircle} alt="plus-circle" />
//         </div>

//         <div
//           className={classes.card}
//           onClick={() => handleCardClick(<BestFirst
//             title1="Crypto Card"
//             title2="Spend your crypto anywhere, anytime."
//             desc1="Rival’s crypto card redefines the way you use your digital assets. Backed by USD and powered by 
//             USDT, our card lets you make purchases globally at Visa and Mastercard merchants without the complexity 
//             of conversions or delays. From online shopping to travel expenses, Rival's crypto card ensures your funds 
//             are accessible anywhere, anytime. With cutting-edge security and no FX fees for USD payments, your crypto 
//             spending is now as easy as swiping a card."
//           />)}
//         >
//           <img className={classes.img1} src={ni2} alt="Component A" />
//           <h3>Spend your crypto anywhere, anytime.</h3>
//           <p>Rival’s crypto card redefines the way you use your digital assets. Backed by USD and powered by USDT, our card lets you make purchases globally at Visa and Mastercard... </p>
//           <img className={classes.img2} src={pluscircle} alt="plus-circle" />
//         </div>


//         <div
//           className={classes.card}
//           onClick={() => handleCardClick(<BestFirst
//             title1="Global Accessibility"
//             title2="Bank without borders."
//             desc1="With Rival, your financial journey isn't limited by geography. Enjoy global access to your funds, 
//             make transactions in multiple currencies, and take advantage of seamless international transfers. 
//             Whether you’re sending money back home or paying for services abroad, Rival eliminates borders and gives 
//             you the freedom to manage your finances wherever life takes you."
//             desc2=" All of this is powered by a platform that’s designed for speed, reliability, and simplicity."
//           />)}
//         >
//           <img className={classes.img1} src={ni3} alt="Component A" />
//           <h3>Bank without borders.</h3>
//           <p>With Rival, your financial journey isn't limited by geography. Enjoy global access to your funds, make transactions in multiple currencies, and take advantage of seamless... </p>
//           <img className={classes.img2} src={pluscircle} alt="plus-circle" />
//         </div>
//         <div
//           className={classes.card}
//           onClick={() => handleCardClick(<BestFirst
//             title1="Security"
//             title2="Your funds, always protected."
//             desc1="At Rival, your security is our priority. We employ advanced encryption, biometric authentication, 
//             and fraud detection systems to keep your account and transactions safe. Our dedicated fraud prevention 
//             team actively monitors suspicious activities, ensuring your funds are protected around the clock."
//             desc2="With ISO-200071 certification and GDPR compliance, Rival delivers the highest standard of data 
//             and fund security, so you can transact with confidence."
//           />)}
//         >
//           <img className={classes.img1} src={ni4} alt="Component A" />
//           <h3>Your funds, always protected.</h3>
//           <p>At Rival, your security is our priority. We employ advanced encryption, biometric authentication, and fraud detection systems to keep your account and transactions safe...</p>
//           <img className={classes.img2} src={pluscircle} alt="plus-circle" />
//         </div>
//         <div
//           className={classes.card}
//           onClick={() => handleCardClick(<BestFirst
//             title1="Plans"
//             title2="Plans tailored to your lifestyle."
//             desc1="Choose from flexible subscription plans that fit your needs. Rival offers options for every user, 
//             giving you access to powerful features and perks designed to enhance your financial experience."
//           />)}
//         >
//           <img className={classes.img1} src={ni5} alt="Component A" />
//           <h3>Plans tailored to your lifestyle.</h3>
//           <p>Choose from flexible subscription plans that fit your needs. Rival offers options for every user, giving you access to powerful features and perks designed to enhance your financial experience.</p>
//           <img className={classes.img2} src={pluscircle} alt="plus-circle" />
//         </div>
//       </div>
//       <RiArrowLeftSLine className={`${classes.arrow} ${classes.left_arrow}`} onClick={scrollLeft} />
//       <RiArrowRightSLine className={`${classes.arrow} ${classes.right_arrow}`} onClick={scrollRight} />

//       {activeModal && <BestModal onClose={closeModal}>{activeModal}</BestModal>}
//     </div>
//   );
// };

// export default Component9;


import React, { useState, useEffect } from 'react';
import classes from './Component9.module.css';
import { FaChevronRight } from 'react-icons/fa';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import halfcircle from '../../asset/halfCircle.png';
import pluscircle from '../../asset/plus-circle.png';
import BestModal from '../../components/rival-best/best-modal/BestModal';
import BestFirst from '../../components/rival-best/best-first/BestFirst';

import ni1 from '../../asset/ni1.png';
import ni2 from '../../asset/ni2.png';
import ni3 from '../../asset/ni3.png';
import ni4 from '../../asset/ni4.png';
import ni5 from '../../asset/ni5.png';

const cardData = [
  {
    img: ni1,
    title: "Make every payment seamless and secure.",
    desc: "At Rival, we believe payments should be simple and stress-free. Whether you're paying for your morning coffee or an international service...",
    modalContent: (
      <BestFirst
        title1="Payments"
        title2="Make every payment seamless and secure."
        desc1="At Rival, we believe payments should be simple and stress-free. Whether you're paying for your 
        morning coffee or an international service, our platform ensures every transaction is instant and 
        hassle-free. With no hidden fees and seamless integration of fiat and crypto options, Rival empowers 
        you to make payments confidently, knowing you're in full control."
        desc2="Pay online, in-store, or even through subscription services with the same level of ease and security."
      />
    ),
  },
  {
    img: ni2,
    title: "Spend your crypto anywhere, anytime.",
    desc: "Rival’s crypto card redefines the way you use your digital assets. Backed by USD and powered by USDT, our card lets you make purchases globally at Visa and Mastercard...",
    modalContent: (
      <BestFirst
        title1="Crypto Card"
        title2="Spend your crypto anywhere, anytime."
        desc1="Rival’s crypto card redefines the way you use your digital assets. Backed by USD and powered by 
        USDT, our card lets you make purchases globally at Visa and Mastercard merchants without the complexity 
        of conversions or delays. From online shopping to travel expenses, Rival's crypto card ensures your funds 
        are accessible anywhere, anytime. With cutting-edge security and no FX fees for USD payments, your crypto 
        spending is now as easy as swiping a card."
      />
    ),
  },
  {
    img: ni3,
    title: "Bank without borders.",
    desc: "With Rival, your financial journey isn't limited by geography. Enjoy global access to your funds, make transactions in multiple currencies, and take advantage of seamless...",
    modalContent: (
      <BestFirst
        title1="Global Accessibility"
        title2="Bank without borders."
        desc1="With Rival, your financial journey isn't limited by geography. Enjoy global access to your funds, 
        make transactions in multiple currencies, and take advantage of seamless international transfers. 
        Whether you’re sending money back home or paying for services abroad, Rival eliminates borders and gives 
        you the freedom to manage your finances wherever life takes you."
        desc2=" All of this is powered by a platform that’s designed for speed, reliability, and simplicity."
      />
    ),
  },
  {
    img: ni4,
    title: "Your funds, always protected.",
    desc: "At Rival, your security is our priority. We employ advanced encryption, biometric authentication, and fraud detection systems to keep your account and transactions safe...",
    modalContent: (
      <BestFirst
        title1="Security"
        title2="Your funds, always protected."
        desc1="At Rival, your security is our priority. We employ advanced encryption, biometric authentication, 
        and fraud detection systems to keep your account and transactions safe. Our dedicated fraud prevention 
        team actively monitors suspicious activities, ensuring your funds are protected around the clock."
        desc2="With ISO-200071 certification and GDPR compliance, Rival delivers the highest standard of data 
        and fund security, so you can transact with confidence."
      />
    ),
  },
  {
    img: ni5,
    title: "Plans tailored to your lifestyle.",
    desc: "Choose from flexible subscription plans that fit your needs. Rival offers options for every user, giving you access to powerful features and perks designed to enhance your financial experience.",
    modalContent: (
      <BestFirst
        title1="Plans"
        title2="Plans tailored to your lifestyle."
        desc1="Choose from flexible subscription plans that fit your needs. Rival offers options for every user, 
        giving you access to powerful features and perks designed to enhance your financial experience."
      />
    ),
  },
];

const Component9 = () => {
  const [activeModal, setActiveModal] = useState(null);
  const [cards, setCards] = useState([...cardData, ...cardData, ...cardData]); // Duplicate the cards
  const carouselRef = React.useRef(null);

  const scrollLeft = () => {
    carouselRef.current.scrollBy({
      left: -420,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({
      left: 420,
      behavior: 'smooth',
    });
  };

  const handleCardClick = (content) => {
    setActiveModal(content);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <div className={classes.financeapplication_parent}>
      <div className={classes.financeapplication_top}>
        <h2>Why rival is the best finance apps</h2>
        <div className={classes.financeapplication_top_right}>
          {/* <a href="">Learn more</a> */}
          {/* <FaChevronRight size={12} color="#246BFD" /> */}
        </div>
      </div>
      <div className={classes.carousel} ref={carouselRef}>
        {cards?.map((card, index) => (
          <div
            key={index}
            className={classes.card}
            onClick={() => handleCardClick(card.modalContent)}
          >
            <img className={classes.img1} src={card.img} alt="Component A" />
            <h3>{card.title}</h3>
            <p>{card.desc}</p>
            <img className={classes.img2} src={pluscircle} alt="plus-circle" />
          </div>
        ))}
      </div>
      <RiArrowLeftSLine className={`${classes.arrow} ${classes.left_arrow}`} onClick={scrollLeft} />
      <RiArrowRightSLine className={`${classes.arrow} ${classes.right_arrow}`} onClick={scrollRight} />

      {activeModal && <BestModal onClose={closeModal}>{activeModal}</BestModal>}
    </div>
  );
};

export default Component9;
