


import React, { useEffect, useRef, useState } from 'react';
import classes from './Component21.module.css';
import phone from '../../asset/i-standardshow.png';
import card from '../../asset/rival-pink-card.png';

const Component21 = () => {
    const imgRef = useRef(null);
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && entry.boundingClientRect.top >= 0) {
                    setShouldAnimate(true);
                }
            },
            { threshold: 0 }
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, []);

    return (
        <div className={classes.parent_component} ref={imgRef}>
            <div className={classes.parent_left}>
                <div className={classes.sc_work_info}>
                    <span className={classes.sc_title1}>Explore plans</span>
                    <label className={classes.sc_title2}>Standard</label>
                    <p className={classes.sc_para}><span>Unlock the future of finance with Rival's Standard Plan for just $6/month! </span>
                        Dive into the world of seamless payments with a card that makes spending as easy as cash. Say goodbye to complicated
                        processes and hello to secure, hassle-free transactions designed to fit your everyday needs. <span>Perfect for first-time
                            users and crypto enthusiasts alike, the Standard Plan is your gateway to a smarter, more innovative financial journey.</span></p>
                    <div className={classes.sc_container}>
                        <button className={classes.sc_button}>Get the app</button>
                        <span className={classes.sc_link}>Learn more</span>
                    </div>
                </div>

            </div>
            <div className={classes.parent_right}>
                <img src={phone} alt="phone" className={classes.right_img1} />
                {/* <img
                    src={card}
                    alt="card"
                className={`${classes.right_img2} ${shouldAnimate ? classes.animate : ''}`}
                /> */}
            </div>
        </div>
    );
};

export default Component21;
