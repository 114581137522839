import React from 'react';
import classes from './CryptoBanner.module.css';

import SimpleAnimation from '../../../components/simple-animation/SimpleAnimation';
import InternationalBtn from '../../../components/international-btn/InternationalBtn';
import vdo from '../../../asset-video/final-crypto.mp4';

const CryptoBanner = () => {
    return (
        <div className={classes.banner_parent}>
            <div className={classes.banner_miniParent}>

       
            <div className={classes.banner_texts}>
                <h2>The First and Best<br/> Crypto Card</h2>
                <p>The First and Only Active Crypto Card in the <br/> Middle East and UAE*</p>
                <InternationalBtn title="Invest in Crypto" />
            </div>
            <SimpleAnimation data={vdo} style={{ position: 'relative', zIndex: 1, marginBottom: '-6px' }} loop={false} />
            </div>
        </div>
    )
}

export default CryptoBanner;
