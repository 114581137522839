import React from 'react';

import AboutUsBanner from '../../build-component/about-us-section/about-us-banner/AboutUsBanner';
import AboutUsDetails from '../../build-component/about-us-section/about-us-details/AboutUsDetails';
import AboutUsSimplify from '../../build-component/about-us-section/about-us-simplify/AboutUsSimplify';
import AboutUsEmpower from '../../build-component/about-us-section/about-us-empower/AboutUsEmpower';
import AboutUsGrowth from '../../build-component/about-us-section/about-us-growth/AboutUsGrowth';
import AboutUsBoard from '../../build-component/about-us-section/about-us-board/AboutUsBoard';
import AboutUsWorktogether from '../../build-component/about-us-section/about-us-worktogether/AboutUsWorktogether';

const AboutUs = () => {
    return (
        <div>
            <AboutUsBanner />
            <AboutUsDetails />
            <AboutUsSimplify />
            <AboutUsEmpower />
            {/* <AboutUsGrowth /> */}
            <AboutUsBoard />
            <AboutUsWorktogether />
        </div>
    )
}

export default AboutUs;