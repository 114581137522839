// import React from 'react';
// import classes from './AboutUsDetails.module.css';

// const AboutUsDetails = () => {
//     return (
//         <div className={classes.parent}>
//             <div className={classes.child}>
//                 <h3>35+ million</h3>
//                 <p>Personal users</p>
//             </div>
//             <div className={classes.child}>
//                 <h3>500k+</h3>
//                 <p>Business users</p>
//             </div>
//             <div className={classes.child}>
//                 <h3>150+</h3>
//                 <p>Countries and regions supported</p>
//             </div>
//             <div className={classes.child}>
//                 <h3>36</h3>
//                 <p>In-app currencies</p>
//             </div>
//         </div>
//     )
// }

// export default AboutUsDetails;

import React, { useState, useEffect } from 'react';
import classes from './AboutUsDetails.module.css';

const AboutUsDetails = () => {
  // Define state for the counters
  const [personalUsers, setPersonalUsers] = useState(0);
  const [businessUsers, setBusinessUsers] = useState(0);
  const [countries, setCountries] = useState(0);
  const [currencies, setCurrencies] = useState(0);

  // Function to animate the counting
  const animateCounter = (targetValue, setState) => {
    let currentValue = 0;
    const increment = Math.ceil(targetValue / 100); // Increment based on target value

    const interval = setInterval(() => {
      if (currentValue < targetValue) {
        currentValue += increment;
        setState(currentValue);
      } else {
        clearInterval(interval);
        setState(targetValue); // Ensure it ends exactly at the target value
      }
    }, 50); // Adjust interval speed here
  };

  useEffect(() => {
    animateCounter(10000, setPersonalUsers);
    animateCounter(130, setBusinessUsers);
    animateCounter(60, setCountries);
    animateCounter(100000000, setCurrencies);
  }, []);

  return (
    <div className={classes.parent}>
      <div className={classes.child}>
        <h3>{personalUsers}+</h3>
        <p>Personal<br/> users</p>
      </div>
      <div className={classes.child}>
        <h3>{businessUsers.toLocaleString()}</h3>
        <p>Supported<br/>currencies</p>
      </div>
      <div className={classes.child}>
        <h3>{countries}+</h3>
        <p>Supported<br/>currencies</p>
      </div>
      <div className={classes.child}>
        <h3>{currencies}+</h3>
        <p>Accepted merchants<br/> globally</p>
      </div>
    </div>
  );
};

export default AboutUsDetails;
