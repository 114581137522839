import React from 'react';
import classes from './Component2.module.css';
import data from '../../asset-video/new-saving-video.mp4';
import InternationalBtn from '../../components/international-btn/InternationalBtn';

const Component2 = () => {
  return (
    <div className={classes.parent}>
      <div className={classes.main}>
        <div className={classes.left}>
          <h2>Spend, Send, and Invest</h2>
          <p><span>Rival simplifies your finances</span>—with your Rival card, send low-cost remittances, and invest in more than 1000 stocks
            and cryptocurrencies, all in one secure platform.</p>
          <InternationalBtn title="Spend now" />
        </div>
        <div className={classes.right}>
          <video
            width="100%"
            height="auto"
            key={data}
            muted
            style={{
              outline: 'none',
              // height: '65vh',
              pointerEvents: 'none',
              
            }}
            autoPlay
            playsInline
            loop={true}
          >
            <source src={data} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  )
}

export default Component2

