import React from 'react';
import classes from './Component25.module.css';
import data from '../../asset-video/earth.mp4';

const Component25 = () => {
    return (
        <div className={classes.parent_container}>
            <div className={classes.left_container}>
                {/* <div className={classes.left_child}> */}
                <label>Overseas without the overspend</label>
                <h3>INTERNATIONAL MONEY TRANSFER</h3>
                <p>Rival offers fast, secure, and affordable money transfers worldwide. With instant Rival-to-Rival transfers, transparent
                    fees, and multiple funding options, including crypto and bank transfers, we make global transactions seamless and reliable.
                    Experience the future of money transfers with Rival.</p>

            </div>
            <div className={classes.right_container}>
                <video
                    width="100%"
                    height="auto"
                    key={data}
                    muted
                    playsInline
                    style={{
                        outline: 'none',
                        pointerEvents: 'none', // Prevent interaction
                    }}
                    autoPlay
                    loop={false}

                    className={classes.vdo}
                >
                    <source src={data} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default Component25


// import React from 'react'

// const Component25 = () => {
//     return (
//         <div>Component25</div>
//     )
// }

// export default Component25;