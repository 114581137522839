import React from "react";
import classes from "./RivalTerms.module.css";
import pdf from "../../../asset/pdf.png";
import pdfFile3 from "../../../asset/PartnerTerms.pdf";

const RivalTerms = () => {
  return (
    <div style={{ background: "#F5F5F7" }}>
      <div className={classes.parent}>
        <div className={classes.top}>
          <h2>Rival Terms</h2>
          <p>
            The Terms were updated on 23 August 2024 and apply to all new Rival
            Personal customers who opened their account on or after 23 August
            2024.
          </p>
          {/* <div className={classes.btnImg}>
                    <div className={classes.btn}>
                        <img src={pdf} alt="pdf" style={{ width: "30px", height: "auto" }} />
                        <p style={{fontSize: "20px",marginTop: "28px",marginLeft: "10px",color:"#000",fontWeight:600}}>Download PDF</p>
                    </div>
                    </div> */}
          <div className={classes.btnImg}>
            <div className={classes.btn}>
              {/* Wrapping the button with an <a> tag for download functionality */}
              <a
                href={pdfFile3}
                download="policy"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={pdf}
                  alt="pdf"
                  style={{ width: "30px", height: "auto" }}
                />
                <p
                  style={{
                    fontSize: "20px",
                    marginTop: "28px",
                    marginLeft: "10px",
                    color: "#000",
                    fontWeight: 600,
                  }}
                >
                  Download PDF
                </p>
              </a>
            </div>
          </div>
        </div>
        <h2 className={classes.heading_title}>1. Introduction</h2>
        <p className={classes.heading_para}>
          Welcome to Rival, a UAE company focused on providing digital services.
          Our partners’ licences have been allocated under several international
          entities which operate across Lithuania, UK, UAE.
        </p>
        <h2 className={classes.heading_title}>2. Scope</h2>
        <p className={classes.heading_para}>
          This Privacy Policy applies to all products and services offered by
          Rival, including our mobile application, website, and any other
          platforms where you may interact with us.
        </p>
        <h2 className={classes.heading_title}>3. Information We Collect</h2>
        <p className={classes.heading_para}>
          At Rival, we collect various types of information to provide you with
          the best digital services. This includes Personal Information,
          Transactional Information, and Technical Information.
        </p>
        <h4 className={classes.heading_heading}>Personal Information</h4>
        <p className={classes.heading_para}>
          When you open an account with us or apply for any of our services, we collect your full name
          to identify you as our customer. We also gather your date of birth to ensure you meet the
          age requirements for using our services. Your National Identification Number is collected for
          verification purposes and to comply with legal obligations. To communicate with you and
          send you important notifications, we require your contact details, which include your email
          address and phone number. Your residential address is collected to establish your location
          and for verification purposes. Additionally, we gather employment information to assess your
          eligibility for certain financial products, such as loans. Financial information, including your
          income and credit history, is also collected to evaluate your financial standing and to tailor
          our services to your needs.

        </p>
        <h4 className={classes.heading_heading}>Transactional Information</h4>
        <p className={classes.heading_para}>
          Once you start using our services, we collect information related to
          your account activities. This includes your account balances to
          provide you with up-to-date information on your financial standing. We
          also keep track of your trading history, which encompasses stocks,
          forex, and cryptocurrency trading activities, to offer you
          personalised advice and services. If you apply for a loan, we collect
          information related to your loan applications and repayments to manage
          your loan account effectively. For those using our prepaid and virtual
          cards, we collect data on card transactions to monitor for fraudulent
          activities and to provide you with transaction summaries.
        </p>
        <h4 className={classes.heading_heading}>Technical Information</h4>
        <p className={classes.heading_para}>
          To enhance your user experience and ensure the secure use of our
          services, we collect technical information. This includes your IP
          address, which helps us identify your location and protect against
          unauthorised access to your account. We also collect information about
          the device you use to access our services, such as the device type and
          operating system, to optimise our app or website for your device. The
          type of browser you use is also collected to ensure compatibility with
          our platforms. Lastly, we use cookies to personalise your experience,
          remember your preferences, and gather analytics data to improve our
          services.
        </p>
        <h2 className={classes.heading_title}>
          4. How We Use Your Information
        </h2>
        <p className={classes.heading_para}>
          The information we collect serves multiple purposes, all aimed at
          providing you with a secure, efficient, and personalised experience.
        </p>
        <h4 className={classes.heading_heading}>To Provide Services</h4>
        <p className={classes.heading_para}>
          First and foremost, the information is used to provide you with the
          range of services we offer. For example, your financial information,
          such as income and credit history, helps us tailor our microlending
          services to your specific needs. Your transactional information, like
          account balances and trading history, allows us to offer you
          personalised advice and insights into your financial activities,
          whether that's in stocks, forex, or cryptocurrency trading
        </p>
        <h4 className={classes.heading_title}>For Verification</h4>
        <p className={classes.heading_para}>
          We use your personal details like your full name, date of birth, and
          National Identification Number for identity verification purposes.
          This is crucial not only for opening a new account but also for
          ongoing transactions and activities. Verification ensures that we
          comply with anti-money laundering (AML) and know-your-customer (KYC)
          regulations, thereby contributing to the overall security and
          integrity of our financial systems.
        </p>
        <h4 className={classes.heading_heading}>For Communication</h4>
        <p className={classes.heading_para}>
          Your contact details, such as email and phone number, are used for
          communication purposes. We send you updates about your account,
          promotional offers that might interest you, and important
          notifications like changes to our terms of service or privacy policy.
          We may also use your contact information to reach out to you for
          feedback to improve our services.
        </p>
        <h4 className={classes.heading_heading}>For Analytics</h4>
        <p className={classes.heading_para}>
          We collect technical information like your IP address, device type,
          and browser type for analytics purposes. This data helps us understand
          how you interact with our services, what features you use the most,
          and what areas may need improvement. By analyzing this information, we
          can enhance the user experience and develop new features that meet
          your needs and expectations.
        </p>
        <h4 className={classes.heading_heading}>For Legal Obligations</h4>
        <p className={classes.heading_para}>
          Lastly, we use your information to comply with legal and regulatory
          requirements. This includes sharing certain data with regulatory
          bodies when mandated by law, as well as using your information for
          auditing purposes. In some cases, we may also be required to disclose
          your information to law enforcement agencies.
        </p>
        <h2 className={classes.heading_title}>
          5. How We Share Your Information
        </h2>
        <p className={classes.heading_para}>
          At Rival, we understand the importance of keeping your information
          confidential. However, there are certain circumstances under which we
          may share your information, always with the aim of providing you with
          better services or to comply with legal obligations.
        </p>
        <h4 className={classes.heading_heading}>Regulatory Bodies</h4>
        <p className={classes.heading_para}>
          To adhere to financial regulations and laws, we may be required to
          share specific information about you and your account activities with
          regulatory bodies. This could include agencies that oversee banking
          and financial services in Lebanon and potentially other jurisdictions
          if you engage in international transactions.
        </p>
        <h4 className={classes.heading_heading}>
          Third-Party Service Providers
        </h4>
        <p className={classes.heading_para}>
          We collaborate with third-party service providers to offer you a
          comprehensive range of services. For instance, we may share your
          information with credit scoring agencies to assess your eligibility
          for loans or with trading platforms to enable your stock, forex, or
          cryptocurrency trading activities. Rest assured, we only partner with
          organisations that adhere to stringent data protection and security
          standards.
        </p>
        <h4 className={classes.heading_heading}>Affiliates and Partners</h4>
        <p className={classes.heading_para}>
          We may share your information with our affiliate companies or business
          partners to offer you additional services or benefits. This could
          include promotional offers or bundled services that are more
          cost-effective. We ensure that all affiliates and partners comply with
          data protection laws and uphold the same standards of privacy as we
          do.
        </p>
        <h4 className={classes.heading_heading}>Law Enforcement Agencies</h4>
        <p className={classes.heading_para}>
          In exceptional circumstances, such as to prevent fraud or other
          illegal activities, we may be required to share your information with
          law enforcement agencies. This would only occur if mandated by law and
          would be done to protect both your interests and the integrity of our
          services.
        </p>
        <h2 className={classes.heading_title}>6. Security Measures</h2>
        <p className={classes.heading_para}>
          At Rival, we take the security of your information extremely
          seriously. We employ a multi-layered approach to protect your data and
          ensure the integrity of our services.
        </p>
        <h4 className={classes.heading_heading}>Encryption</h4>
        <p className={classes.heading_para}>
          All data transmitted between you and our servers is encrypted using
          advanced encryption algorithms. This ensures that your personal and
          financial information is secure during transmission and cannot be
          intercepted by unauthorised parties.
        </p>
        <h4 className={classes.heading_heading}>Firewalls</h4>
        <p className={classes.heading_para}>
          Our systems are protected by state-of-the-art firewalls that monitor
          and control incoming and outgoing network traffic. These firewalls act
          as a barrier between your data and external threats, allowing only
          legitimate traffic to pass through.
        </p>
        <h4 className={classes.heading_heading}>Multi-Factor Authentication</h4>
        <p className={classes.heading_para}>To provide an additional layer of security, we implement multi-factor authentication (MFA) for
          account access. This requires you to provide two or more verification methods—a password,
          a mobile verification code, or biometric data like a fingerprint—to access your account. This
          makes it exceedingly difficult for unauthorised users to gain access to your account.
        </p>
        <h4 className={classes.heading_heading}>Regular Audits and Monitoring
        </h4>
        <p className={classes.heading_para}>We conduct regular security audits to assess the effectiveness of our security measures.
          This includes both internal audits and third-party assessments to ensure compliance with
          industry standards and regulations. Our systems are also continuously monitored for any
          signs of vulnerabilities or unauthorised activities.
        </p>
        <h4 className={classes.heading_heading}>Employee Training
        </h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>Our employees undergo rigorous training in data protection and security protocols. Access to
          customer data is restricted to authorised personnel only, and all employees are bound by
          confidentiality agreements.
        </p>
        <p className={classes.heading_para}>By employing these security measures, we aim to protect your information from
          unauthorised access, disclosure, alteration, and destruction. While no system can guarantee
          100% security, we strive to provide the highest level of protection possible.
        </p>
        <h2>7. Your Rights</h2>
        <p className={classes.heading_para}>As a user of Rival's services, you have several rights concerning your personal data, and we
          are committed to ensuring you can exercise them effectively.</p>
        <h4 className={classes.heading_heading}>Access to Your Data
        </h4>
        <p className={classes.heading_para}>You have the right to request access to the personal information we hold about you. This
          enables you to receive a copy of your data and verify that we are processing it lawfully. We
          provide a user-friendly dashboard within our app where you can view and manage your
          personal information.</p>
        <h4 className={classes.heading_heading}>Correction of Your Data
        </h4>
        <p className={classes.heading_para}>If you believe that any of the information we hold about you is incorrect or incomplete, you
          have the right to request a correction. We take prompt action to update your information in
          our records and, where applicable, notify third parties to whom we have disclosed your data.</p>
        <h4 className={classes.heading_heading}>Deletion of Your Data</h4>
        <p className={classes.heading_para}>You have the right to request the deletion of your personal data under certain conditions,
          such as when the data is no longer necessary for the purposes for which it was collected.
          However, this right is not absolute and may be subject to legal or regulatory requirements
          that require us to retain your data for a specific period.</p>
        <h4 className={classes.heading_heading}>Portability of Your Data</h4>
        <p className={classes.heading_para}>You also have the right to data portability, which allows you to receive your personal data in
          a structured, commonly used, and machine-readable format. You can then transfer this data
          to another service provider if you wish.
        </p>
        <h4 className={classes.heading_heading}>Opt-Out and Consent Withdrawal</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>You have the right to opt-out of certain uses of your personal data, such as direct marketing.
          Additionally, if we are processing your data based on your consent, you have the right to
          withdraw that consent at any time, although this will not affect any processing that has
          already taken place.
        </p>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>We have established procedures to enable you to exercise these rights easily and at no
          cost. Should you wish to exercise any of these rights, you can do so through the settings in
          our app or by contacting our customer support team.</p>
        <p className={classes.heading_para}>Great! The next section is "Cookies," which explains how and why cookies are used on your
          platform. Here's an extended sample of that section in paragraph form:
        </p>
        <h2 className={classes.heading_title}>8. Cookies</h2>
        <p className={classes.heading_para}>Cookies are small text files that are stored on your device when you visit our website or use
          our app. At Rival, we use cookies to enhance your user experience, improve our services,
          and for analytics.
        </p>
        <h4 className={classes.heading_heading}>Types of Cookies
        </h4>
        <h4 className={classes.heading_heading}>Session Cookies</h4>
        <p className={classes.heading_para}>These are temporary cookies that expire when you close your browser or app. Session
          cookies enable you to move from page to page within our website or app without having to
          log in repeatedly.
        </p>
        <h4 className={classes.heading_heading}>Persistent Cookies</h4>
        <p className={classes.heading_para}>These cookies remain on your device even after you have closed your browser or app.
          Persistent cookies remember your preferences and actions, making it easier for you to
          navigate our services during future visits.</p>
        <h4 className={classes.heading_heading}>Third-Party Cookies
        </h4>
        <p className={classes.heading_para}>We may also use third-party cookies, which are cookies from a domain different than that of
          our website or app. These are often used for tracking and advertising purposes. For
          example, we may use third-party analytics tools that set their own cookies to collect data on
          how people are using our services.
        </p>
        <h4 className={classes.heading_heading}>Opting Out</h4>
        <p className={classes.heading_para}>You have the option to manage or opt-out of cookies through your browser settings. Most
          browsers allow you to block cookies, delete existing cookies, or warn you before a cookie is
          stored on your device. Please note that if you choose to disable cookies, some features of
          our services may not function properly.
        </p>
        <h4 className={classes.heading_heading}>Analytics</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>We use cookies to gather analytics data, which helps us understand how users interact with
          our services. This data is invaluable for improving the functionality and user-friendliness of
          our website and app.</p>
        <p className={classes.heading_para}>By using our services, you consent to the use of cookies as described in this policy. You can
          withdraw your consent at any time by changing your cookie settings.
        </p>
        <h2 className={classes.heading_title}>9. Data Retention Policy</h2>
        <p className={classes.heading_para}>At Rival, we are committed to retaining your personal data only for as long as is necessary to
          fulfil the purposes for which it was collected. Our data retention policy is designed to respect
          both legal obligations and your privacy</p>
        <h4 className={classes.heading_heading}>Duration of Retention
        </h4>
        <p className={classes.heading_para}>Generally, we retain your personal data for a period of seven years from the end of your
          relationship with us, in accordance with financial regulations by our partners’. However, the
          retention period may vary depending on the type of data and the specific service involved.
          For example, transactional data related to loans may be retained for a different period than
          data related to stock trading.
        </p>
        <h4 className={classes.heading_heading}>Legal and Regulatory Requirements</h4>
        <p className={classes.heading_para}>In some cases, we are legally obligated to retain certain types of data for a specific period.
          This could be due to anti-money laundering laws, tax regulations, or other financial
          compliance requirements. In such cases, we will retain your data in accordance with these
          laws.
        </p>
        <h4 className={classes.heading_heading}>Data Archiving and Deletion</h4>
        <p className={classes.heading_para}>After the retention period has expired, we will securely archive or delete your personal data.
          Archived data is stored in a secure environment and is accessible only by authorised
          personnel. If deletion is required, we will employ secure methods to ensure that your data is
          completely removed from our systems.
        </p>
        <h4 className={classes.heading_heading}>User-Requested Deletion</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>You have the right to request the deletion of your personal data, as outlined in the "Your
          Rights" section. However, please note that this right is not absolute and may be subject to
          legal or regulatory requirements that necessitate the continued retention of your data</p>
        <p className={classes.heading_para}>By using our services, you acknowledge and agree to our data retention policy. If you have
          any questions or concerns about how long we retain your data, you are welcome to contact
          our customer support team for further information.
        </p>
        <h2 className={classes.heading_title}>10. Third-Party Links
        </h2>
        <p className={classes.heading_para}>Our website and app may contain links to third-party websites, platforms, or services that are
          not owned or controlled by Rival. These links are provided for your convenience and to
          enhance the range of services we can offer, such as linking to financial news sites, trading
          platforms, or payment gateways.
        </p>
        <h4 className={classes.heading_heading}>Not Covered by This Policy</h4>
        <p className={classes.heading_para}>It's important to note that these third-party websites have their own privacy policies and data
          collection practices, which are not covered by Rival's Privacy Policy. We do not have control
          over the content or privacy practices of these external sites. Therefore, we cannot assume
          responsibility for the protection and privacy of any information you provide while visiting such
          sites.
        </p>
        <h4 className={classes.heading_heading}>User Responsibility</h4>
        <p className={classes.heading_para}>We encourage you to exercise caution and review the privacy policies of any third-party
          websites you choose to visit. If you follow a link to a third-party website, you do so at your
          own risk. We recommend reading their privacy policy and terms of service before providing
          any personal information.
        </p>
        <h4 className={classes.heading_heading}>Security Measures</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>While we strive to offer links only to reputable third-party services that uphold high standards
          of data protection, we cannot guarantee the security of your data once it leaves our platform.
          However, we regularly review the third-party services we link to, ensuring they meet our
          standards for security and privacy.</p>
        <p className={classes.heading_para}>By using our services and accessing third-party links provided within our platform, you
          acknowledge that you understand the risks and implications concerning data privacy when
          navigating to these external sites.
        </p>
        <h2 className={classes.heading_title}>11. Children’s Privacy
        </h2>
        <p className={classes.heading_para}>Rival is committed to protecting the privacy of children. Our services are not designed for or
          directed at individuals under the age of 18. We do not knowingly collect, use, or disclose
          personal information from children under 18.
        </p>
        <h4 className={classes.heading_heading}>Age Verification
        </h4>
        <p className={classes.heading_para}>During the account creation process, we require users to provide their date of birth to verify
          their age. If we become aware that an account has been created by an individual under the
          age of 18, we will take immediate steps to close the account and delete any personal
          information associated with it.
        </p>
        <h4 className={classes.heading_heading}>Parental Consent
        </h4>
        <p className={classes.heading_para}>If we discover that we have inadvertently collected information from a child under the age of
          18 without verifiable parental consent, we will delete that information as quickly as possible.
          Parents or guardians who become aware that their child has provided us with personal
          information without their consent are encouraged to contact us immediately.
        </p>
        <h4 className={classes.heading_heading}>Reporting and Compliance</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>We comply with all applicable laws and regulations concerning the protection of children's
          privacy, including the Children's Online Privacy Protection Act (COPPA) where relevant. If
          you have any concerns about children’s privacy related to our services, please contact our
          customer support team for prompt resolution</p>
        <p className={classes.heading_para}>By using our services, you confirm that you are at least 18 years old or have obtained valid
          parental or guardian consent to use our services if under the age of 18.</p>
        <h2 className={classes.heading_title}>
          12. International Data Transfers
        </h2>
        <p className={classes.heading_para}>Rival operates primarily within Lebanon, but we may transfer and process your personal
          data in other countries for various reasons, such as utilising international cloud storage
          solutions or third-party service providers located abroad.
        </p>
        <h4 className={classes.heading_heading}>Compliance with Laws</h4>
        <p className={classes.heading_para}>When transferring your data internationally, we comply with applicable data protection laws,
          including ensuring that your personal data is transferred to countries that provide an
          adequate level of data protection. We also use legal mechanisms like Standard Contractual
          Clauses to ensure that your data is treated securely and in accordance with this Privacy
          Policy.
        </p>
        <h4 className={classes.heading_heading}>Data Encryption
        </h4>
        <p className={classes.heading_para}>To further protect your data during international transfers, we employ advanced encryption
          technologies. This ensures that your data remains secure when transmitted across borders</p>
        <h4 className={classes.heading_heading}>User Consent
        </h4>
        <p className={classes.heading_para}>By using our services, you consent to the transfer of your data outside of Lebanon. If you do
          not wish for your data to be transferred internationally, you may opt-out by contacting our
          customer support team. However, please note that opting out may affect the availability and
          functionality of certain services.</p>
        <h4 className={classes.heading_heading}>Risk Mitigation</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>We take all reasonable steps to ensure that your data is treated securely and in accordance
          with this Privacy Policy when it is transferred internationally. This includes conducting regular
          audits of our international data transfer practices and third-party service providers to ensure
          compliance with data protection laws</p>
        <p className={classes.heading_para}>Excellent! The next section is "Automated Decision-Making," which outlines how algorithms
          or automated systems may be used for decision-making processes like credit scoring or
          trading advice. Here's an extended sample of that section in paragraph form:</p>
        <h2 className={classes.heading_title}>13. Automated Decision-Making
        </h2>
        <p className={classes.heading_para}>Rival employs automated decision-making algorithms to provide various services, such as
          credit scoring for microlending and trading advice for stocks, forex, and cryptocurrency.
          These algorithms analyse your personal and transactional data to offer personalised
          financial solutions.</p>
        <h4 className={classes.heading_heading}>Credit Scoring
        </h4>
        <p className={classes.heading_para}>Our automated credit scoring system evaluates your financial information, including income,
          credit history, and transactional data, to determine your eligibility for loans. The algorithm
          calculates a credit score that is used to approve or deny loan applications and to set interest
          rates</p>
        <h4 className={classes.heading_heading}>Trading Advice</h4>
        <p className={classes.heading_para}>We also use automated algorithms to offer trading advice. These algorithms analyse market
          data, trends, and your trading history to provide personalised trading recommendations.
          However, it's important to note that while these recommendations are data-driven, they do
          not guarantee success and should not be considered as financial advice</p>
        <h4 className={classes.heading_heading}>User Rights
        </h4>
        <p className={classes.heading_para}>You have the right to know the logic involved in automated decision-making processes that
          have legal or similarly significant effects on you. You also have the right to challenge
          decisions made solely based on automated processing and request human intervention.
        </p>
        <h4 className={classes.heading_heading}>Transparency and Accountability</h4>
        <p style={{ marginBottom: '20px' }} className={classes.heading_para}>We are committed to being transparent about the use of automated decision-making and the
          data that feeds into these algorithms. We regularly review and update our algorithms to
          ensure they are accurate and unbiased</p>
        <p className={classes.heading_para}>By using our services that involve automated decision-making, you acknowledge and
          consent to the processing of your data in this manner. If you have any concerns or questions
          about our use of automated decision-making, you are welcome to contact our customer
          support team.
        </p>
        <h2 className={classes.heading_title}>14. Dispute Resolution
        </h2>
        <p className={classes.heading_para}>We are committed to resolving any disputes or concerns you may have regarding your
          privacy and our use of your personal data. Our dispute resolution process is designed to be
          fair, impartial, and timely.</p>
        <h4 className={classes.heading_heading}>Initial Contact</h4>
        <p className={classes.heading_para}>If you have a privacy-related concern or dispute, we encourage you to first contact our
          customer support team. We aim to acknowledge your concern within 48 hours and resolve it
          as quickly as possible.
        </p>
        <h4 className={classes.heading_heading}>Internal Review
        </h4>
        <p className={classes.heading_para}>If your concern is not resolved to your satisfaction through initial contact with customer
          support, you may request an internal review. This review will be conducted by a team of
          experts within Rival who are trained in data protection and privacy laws. They will investigate
          the issue thoroughly and provide a resolution.
        </p>
        <h4 className={classes.heading_heading}>Third-Party Mediation</h4>
        <p className={classes.heading_para}>If the internal review does not resolve the dispute, you have the option to seek third-party
          mediation. We are open to cooperating with neutral and reputable third-party organizations
          to mediate the dispute.
        </p>
        <h4 className={classes.heading_heading}>Legal Remedies
        </h4>
        <p className={classes.heading_para}>As a last resort, you may choose to pursue legal remedies. However, we strongly encourage
          you to use the aforementioned channels for a quicker and less costly resolution.
        </p>
        <h4 className={classes.heading_heading}>Timeframe for Resolution</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>We aim to resolve all disputes within 30 days from the date of the initial complaint. However,
          complex issues may require more time, and if that is the case, we will keep you informed
          throughout the process.
        </p>
        <p className={classes.heading_para}>By using our services, you agree to this dispute resolution process and commit to resolving
          any privacy disputes in accordance with the terms outlined in this section.
        </p>
        <h2 className={classes.heading_title}>15. Data Breach Procedure</h2>
        <p className={classes.heading_para}>Despite our best efforts to ensure the security of your data, no system is completely
          foolproof. In the unfortunate event of a data breach, Rival has a comprehensive procedure in
          place to manage and mitigate the impact.
        </p>
        <h4 className={classes.heading_heading}>Immediate Action</h4>
        <p className={classes.heading_para}>Upon discovering a data breach, we will immediately activate our incident response team to
          identify the scope of the breach and take steps to contain it. This may include temporarily
          disabling certain services or features to prevent further unauthorised access.
        </p>
        <h4 className={classes.heading_heading}>Notification</h4>
        <p className={classes.heading_para}>We are committed to notifying affected users as soon as possible. Depending on the severity
          and nature of the breach, notifications may be sent via email, in-app messages, or even by
          postal mail. We will also report the breach to relevant regulatory bodies as required by law.
        </p>
        <h4 className={classes.heading_heading}>Remediation</h4>
        <p className={classes.heading_para}>Our team will work diligently to resolve the security vulnerability that led to the data breach.
          This may involve software updates, changes in security protocols, or other measures to
          enhance data protection.
        </p>
        <h4 className={classes.heading_heading}>Transparency and Updates</h4>
        <p className={classes.heading_para}>We will keep you informed about the progress of our investigation and remediation efforts.
          This may include periodic updates via email or through announcements on our website and
          app.
        </p>
        <h4 className={classes.heading_heading}>Post-Incident Review</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>After resolving the breach, we will conduct a thorough review to understand how it occurred
          and to prevent similar incidents in the future. This may result in updates to our security
          measures and privacy policy</p>
        <p className={classes.heading_para}>By using our services, you acknowledge and agree to our data breach procedure. If you
          have any questions or concerns about how we handle data breaches, you are welcome to
          contact our customer support team.
        </p>
        <h2 className={classes.heading_title}>16. Changes to This Privacy Policy
        </h2>
        <p className={classes.heading_para}>Rival reserves the right to update or modify this Privacy Policy at any time to reflect changes
          in our practices, services, or legal obligations. Any changes we make will be effective
          immediately upon posting the updated policy on our website and app.</p>
        <h4 className={classes.heading_heading}>Notification</h4>
        <p className={classes.heading_para}>We will notify you of any significant changes to this Privacy Policy through various channels.
          This may include sending you an email, displaying a notice within the app, or updating the
          "Last Modified" date at the top of this policy.
        </p>
        <h4 className={classes.heading_heading}>User Responsibility</h4>
        <p className={classes.heading_para}>It is your responsibility to review this Privacy Policy periodically to stay informed about our
          data collection and processing practices. Your continued use of our services after any
          changes to this policy constitutes your acceptance of the updated terms</p>
        <h4 className={classes.heading_heading}>Previous Versions
        </h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>For your reference, we will archive previous versions of this Privacy Policy and make them
          accessible on our website. This allows you to review how your data has been handled over
          time.
        </p>
        <p className={classes.heading_para}>By using our services, you acknowledge and agree to abide by the terms outlined in this
          Privacy Policy, including any future updates or modifications. If you have any questions or
          concerns about changes to this policy, you are welcome to contact our customer support
          team</p>
        <h2 className={classes.heading_title}>17. Contact Us
        </h2>
        <p className={classes.heading_para}>If you have any questions, concerns, or comments regarding this Privacy Policy or our data
          handling practices, we encourage you to reach out to us. We are committed to resolving any
          issues and providing you with the information you need.
        </p>
        <h4 className={classes.heading_heading}>Email
        </h4>
        <p className={classes.heading_para}>For general inquiries or concerns about privacy, you can email us at: “support@rival.finance”</p>
        <h4 className={classes.heading_heading}>In-App Support</h4>
        <p style={{ marginBottom: "20px" }} className={classes.heading_para}>Alternatively, you can use the in-app support feature within the Rival app to send a support
          ticket and we will respond as soon as possible.
        </p>
        <p className={classes.heading_para}>We aim to respond to all inquiries within 12 hours. Your privacy is important to us, and we
          are here to assist you in any way we can.</p>
      </div>
    </div>
  );
};

export default RivalTerms;
