import React from 'react';
import classes from './Component26.module.css';
import data from '../../asset-video/world-new.mp4';
import InternationalBtn from '../../components/international-btn/InternationalBtn';

const Component25 = () => {
    return (
        <div className={classes.parent_container}>
            <div className={classes.left_container}>
                <h3>Spend and send your crypto globally</h3>
                <p style={{ marginBottom: "20px",opacity: 0.6 }}>Now with Rival crypto you can send, spend and acquire crypto as easy as any other currency</p>
                <InternationalBtn title="Spend Now" />

            </div>
            <div className={classes.right_container}>
                <video
                    width="100%"
                    height="auto"
                    key={data}
                    playsInline
                    muted
                    autoPlay
                    style={{
                        outline: 'none',
                        pointerEvents: 'none', // Prevent interaction
                      }}
                    loop={false}
                    className={classes.vdo}
                >
                    <source src={data} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default Component25


// import React from 'react'

// const Component25 = () => {
//     return (
//         <div>Component25</div>
//     )
// }

// export default Component25;