import React from 'react';
import classes from './Component27.module.css';
import data from '../../asset-video/new-saving-video.mp4';

const Component27 = () => {
    return (
        <div className={classes.parent}>

            <video
                className={classes.vdo}
                key={data}
                muted
                playsInline
                autoPlay
                loop={true}
                style={{
                    outline: 'none',
                    // height: '65vh',
                    pointerEvents: 'none',
                    
                  }}
            >
                <source src={data} type="video/mp4" />
            </video>
        </div>
    )
}

export default Component27;