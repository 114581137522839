import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Terms.module.css';

import file from '../../asset/blue-file.png';

const Terms = () => {
    const navigate = useNavigate();
    return (
        <div className={classes.terms_parent}>
            <h2>Website Terms</h2>
            <div className={classes.child} onClick={() => navigate('/rival-terms')}>
                <div className={classes.child_child}>
                    <img src={file} alt="slkdf" />
                    <h4>Rival Terms</h4>
                    <p>07/01/2025</p>
                </div>
                <div className={classes.child_child} onClick={() => navigate('/partners-terms')}>
                    <img src={file} alt="slkdf" />
                    <h4>Partner Terms</h4>
                    <p>07/01/2025</p>
                </div>
            </div>
        </div>
    )
}

export default Terms;