import React from 'react';
import classes from './Component29.module.css';
import { FaAngleRight } from "react-icons/fa6";

import bag from '../../asset/wallet-logo.png';
import horizontal from '../../assets/line-horizontal.png';
import T from '../../asset/black-white-t.png';
import zero from '../../asset/zero-per.png';
import one from '../../asset/one-per.png';
import two from '../../asset/two-per.png';
import three from '../../asset/three-per.png'
import bit from '../../asset/bitcoin-logo.png';
import ninja from '../../asset/ninja.png';


const Component29 = () => {
    return (
        <div className={classes.parent}>
            <div className={classes.main}>
                <h2>Rival Basics</h2>
                <p>Get help choosing. <span>Chat with a Specialist </span><FaAngleRight color="#246BFD" size={15} /></p>
                <label>Non Subscribers</label>
                <div className={classes.main_child}>
                    <Child img1={bag} desc1="Card Limit" desc2="0" />
                    <Child img1={T} img2={horizontal} desc1="Deposit Into Card" desc2="(USDT+USDT)" />
                    <Child img1={zero} img2={horizontal} desc1="Fee add money" desc2="to card" />
                    <Child img1={one} img2={horizontal} desc1="Fee add money" desc2="to card" />
                    <Child img1={two} img2={horizontal} desc1="Fee add money" desc2="to card" />
                    <Child img1={three} img2={horizontal} desc1="Fee add money" desc2="to card" />
                    <Child img1={bit} desc1="Crypto Withdrawal" desc2="Limit" label="$10,000.00 per Month" />
                    <Child img1={ninja} desc1="Exchange" desc2="Limit" label="$2,500.00" />
                    <div className={classes.xtra}> </div>
                </div>
            </div>
        </div>
    )
}

const Child = ({ img1, img2, desc1, desc2, label }) => {
    return (
        <div className={classes.child_child}>
            <img src={img1} alt="img1" className={classes.child_image} />
            <p style={{ margin: "0", padding: "0" }} className={classes.child_desc}>{desc1}</p>
            <p style={{ margin: "0", padding: "0" }} className={classes.child_desc}>{desc2}</p>
            {img2 ? <img src={img2} alt="img2" className={classes.child_img} /> : ''}
            {label ? <label style={{ fontSize: "13px", fontWeight: "400", margin: "15px 0" }}>{label}</label> : ''}
        </div>
    )
}


export default Component29