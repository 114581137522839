import React from 'react';
import classes from './InvestingToggle.module.css';
import HideShowToggle from '../hide-show-toggle/HideShowToggle';

const InvestingToggle = () => {

    const data = [
        {
            heading: "Who can invest using Rival?",
            description: "All Rival subscribers, regardless of their subscription tier (Standard, Plus, or Pro), will have access to our investing platform. Each tier offers unique benefits to enhance your investing experience."
        },
        {
            heading: "Are there fees for investing on Rival?",
            description: 'Rival ensures transparency in all transactions. Fees vary based on your subscription tier, with lower fees available for Plus and Pro users. Details on fees will be clearly displayed before completing any transaction.'
        },
        {
            heading: "Can I use my USDT to invest?",
            description: "Absolutely. Rival’s integration with USDT allows you to seamlessly invest using your crypto funds, providing flexibility and convenience for digital asset holders."
        },
        {
            heading: "When will investing be available?",
            description: "Rival investing services will be available in Q3 2025. For more information stay connected on our socials and communication channels"
        },
    ];
    return (
        <div className={classes.compare_toggle}>
            <label>Frequently Asked Questions</label>
            <h2>Investing FAQs</h2>
            <HideShowToggle data={data} />
        </div>
    )
}

export default InvestingToggle;




