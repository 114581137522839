import React from 'react';
import classes from './CryptoToggle.module.css';
import HideShowCrypto from '../hide-show-crypto/HideShowCrypto';

const CryptoToggle = () => {

    const data = [
        {
            heading: "How is my crypto secured?",
            description: "Rival is ISO-certified, adhering to the highest international standards for information security management. We protect your crypto with advanced encryption, multi-signature wallets, and rigorous compliance measures. Your assets remain in your hands, backed by globally recognized security protocols."
        },
        {
            heading: "Card Transaction Fees",
            description: "Rival does not charge any fees on card transactions made with your currency-backed card. While other fees, such as international or network charges, may apply, these are determined by third-party institutions. Rest assured, Rival does not profit from these fees, ensuring a transparent and fair experience for our users."
        },
        {
            heading: "What are the spending limits on Rival cards?",
            description: "Rival cards come with generous spending limits designed to meet your needs. Limits may vary based on your subscription plan and KYC verification level. These limits ensure secure transactions while offering flexibility for both everyday purchases and larger payments. For specific details on your plan's limits, please refer to your account settings or your dedicated account manager"
        },
        {
            heading: "What does Rival mean by acquiring?",
            description: "Rival's acquiring feature allows you to seamlessly receive crypto and convert it directly into fiat currency, which can then be debited back into your bank account. This innovative solution bridges the gap between digital assets and traditional banking, offering unmatched convenience. Launching in Q2 2025, this feature will revolutionize how you manage and utilize crypto. Follow us on social media to stay updated on the latest developments!"
        }
    ];
    return (
        <div className={classes.compare_toggle}>
            <label>Frequently Asked Questions</label>
            <h2>Crypto FAQs</h2>
            <HideShowCrypto data={data} />
        </div>
    )
}

export default CryptoToggle;




