import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../Context/AuthContext";
import classes from "./NeedHand.module.css";
import { AiOutlineSearch } from "react-icons/ai";
import NeedHandChild from "./NeedHandChild";

const data = [
  {
    title: "Security and fraud",
    path: "/help&support-details",
    questions: [
      {
        question: "Rival's operations and licenses",
        answer:
          "Rival has a full range of partnerships and licenses across numerous countries jurisdictions. Rival has a Payments Services Provider license (PSP) (license No. 1308095) to provide card issuing and acquiring services. Other partnerships and sublicenses incude a Lithunanian Electric Money Transfer license (E.M.T) (license No. 33)For more information you could contact us on legal@rival.finance or through our support team.",
      },
      {
        question: "How does Rival ensure the security of my account?",
        answer:
          "Rival ensures the security of your account through multiple layers of protection. We use advanced encryption to safeguard your personal information and transactions. Additionally, we require a passcode for accessing the app, and we verify your identity using your email and phone number. For added security, we also support biometric authentication, such as fingerprint or facial recognition, on compatible devices.If you suspect any fraudulent activity please contact us in our support chat",
      },
      {
        question:
          "What should I do if I suspect fraudulent activity on my account?",
        answer:
          "If you notice any suspicious activity on your account, it's crucial to take immediate action to protect your funds and personal information. Here's a step-by-step guide to help you address the issue effectively:\n\n1. Contact Support Immediately\n   - Use the in-app support feature to report suspicious activity. This ensures a quick response from our team.\n   - If you cannot access the app, contact our support team via the provided phone number or by emailing support@rival.finance.\n\n2. Lock Your Account\n   - Request our support team to temporarily lock your account. This prevents any further unauthorized transactions while the issue is being investigated.\n   - As we have different ways to restrict suspicious activity, please proceed with changing your passcode with our support.\n\n3. Review Recent Transactions\n   - Carefully review your recent transactions to identify any that you did not authorize.\n   - Provide details of any unauthorized transactions to the support team to aid in their investigation.\n\n4. Monitor Your Account\n   - Regularly monitor your account for any further suspicious activity.\n   - Set up transaction alerts via Profile > Notifications to stay informed of any account activity in real-time.\n\n5. Follow Up\n   - Stay in contact with the support team for updates on the investigation.\n   - Ensure that any unauthorized transactions are reversed and your account is secure before resuming normal activity.\n\n6. Educate Yourself on Fraud Prevention\n   - Be aware of phishing scams and avoid clicking on suspicious links or providing personal information to unverified sources.\n   - Secure Devices: Ensure that your devices (phone, computer) are secure and free from malware or spyware.\n\nFor more information on how you can protect yourself, contact us at support@rival.finance.",
      },
      {
        question: "How can I protect myself from phishing scams?",
        answer:
          "Phishing scams are deceptive attempts to steal your personal information by pretending to be a legitimate source. It's essential to stay vigilant and recognize these scams to protect your Rival account. Follow these tips to safeguard your information:\n\n1. Verify Authenticity\n   - Always ensure you are accessing Rival through the official app or website. Bookmark our official site to avoid mistyped URLs leading to phishing sites.\n\n2. Beware of Suspicious Messages\n   - Be cautious of emails, messages, or calls asking for your login credentials, personal information, or urging you to take immediate action. Check for spelling errors or unusual sender addresses.\n\n3. Check Sender Details\n   - Scammers often use email addresses that look similar to legitimate ones. Always double-check the sender's email address and domain carefully.\n\n4. Avoid Clicking Unknown Links\n   - Never click on suspicious links or download attachments from unknown or untrusted sources. If in doubt, visit our official website directly.\n\n5. Report Suspicious Activity\n   - If you receive a suspicious message claiming to be from Rival, do not respond. Report it to our support team immediately.\n\n6. Rival's Policy\n   - Remember, Rival will never ask for your password, PIN, or sensitive information via email or phone. Always be skeptical of unsolicited requests for such information.",
      },
      {
        question: "How does Rival monitor and detect fraudulent activities?",
        answer:
          "Rival takes fraud prevention seriously and employs a multifaceted approach to ensure your account's security. Our advanced systems and dedicated team work together to detect and address any suspicious activity. Follow our fraud detection methods below:\n\n1. Advanced Fraud Detection Systems\n   - Rival employs sophisticated fraud detection systems that continuously monitor account activity for unusual or suspicious behavior. These systems use machine learning algorithms to analyze transaction patterns and detect anomalies in real-time.\n\n2. Behavioral Analysis\n   - Our system establishes a baseline of normal user behavior by analyzing transaction history, frequency, and typical transaction amounts. Any deviation from this established baseline triggers an alert for further investigation.\n\n3. Real-Time Alerts and Notifications\n   - If potentially fraudulent activity is detected, the system automatically flags the transaction. Users receive real-time alerts via the app, prompting them to verify specific activities. This immediate notification helps prevent unauthorized transactions.\n\n4. Dedicated Fraud Prevention Team\n   - In addition to automated systems, Rival has a dedicated team of fraud prevention experts who review flagged activities. This team conducts in-depth investigations and takes swift action to protect users' accounts.\n\n5. User Verification\n   - To ensure account security, users may be asked to verify certain activities. This verification process includes confirming transactions via email, phone number, or biometric authentication.",
      },
      {
        question: "What is KYC, and why is it mandatory?",
        answer:
          "KYC (Know Your Customer) is a regulatory requirement designed to confirm your identity and ensure the security and integrity of the financial services we provide. This process helps prevent fraud and other illegal activities.",
      },
      {
        question: "Is rival a bank?",
        answer:
          "Rival is not a bank. It provides financial services through a network of global and local licenses, including those in the UAE and Lithuania. Rival’s wide range of services is supported by a robust legal infrastructure developed over two decades in the financial services industry. Please note, Rival does not hold deposits or store users' funds in its accounts. Instead, it facilitates financial services through trusted international providers. For more information, feel free to contact us at legal@rival.finance.",
      },
    ],

    desc1: "Rival's operations and licenses",
    desc2: "How does Rival ensure the security of my account?",
    desc3: "What should I do if I suspect fraudulent activity on my account?",
  },
  {
    title: "Account managment",
    path: "/help&support-details",
    // path: "/help&support-details/help3",
    questions: [
      {
        question: "How do I create an account on Rival?",
        answer:
          "To create an account, download the Rival app from the App Store or Google Play, and follow the on-screen instructions to sign up using your phone number and personal details. To access full functionality of our services, you will have to subscribe to a plan and verify your KYC. Any failure of verifying your account will result in limited accessibility.",
      },
      {
        question: "Can I link my Rival Finance account to my bank account?",
        answer: "Account linkage with other bank accounts is not possible.",
      },
      {
        question: "How to i update my personal information?",
        answer: "For security reasons, some details could not be changed and can only be changed with contacting customer support However for the details that could be changed, go to the Home Screen > Profile Icon > Profile Change the details you would like to change, and press the save details button",
      },
      {
        question: "What should i do if i forgot my passcode?",
        answer: "If you forget your passcode, click on 'Forgot passcode on the login screen and follow the instructions to reset it. You will receive a verification code on your registered phone number.",
      }, {
        question: "How can i close my rival account?",
        answer: "To close your account go to your home screen profile >. Please note that closing your account is irreversible and you will lose access to all your Rival services. Do not worry, if there is any money still available in your account, you could contact support through support@rival.finance to retrieve the rest of your balance.",
      },

    ],
    desc1: "How do I create an account on Rival?",
    desc2: "Can I link my Rival Finance account to my bank account?",
    desc3: "How to i update my personal information?",
  },
  {
    title: "Adding money",
    path: "/help&support-details",
    // path: "/help&support-details/help2",
    questions: [
      {
        question: "How do i add money to my rival account?",
        answer: "As Rival continues to grow locally and internationally, you can add money to your Rival account via bank account or using a debit/credit card or through local cash providers in some areas",
      },
      {
        question: "Are there any fees fo adding money to my rival account?",
        answer: "Adding money via bank transfer may have fees depending on your bank sender.We take a small fee on all bank transfer deposits. Adding money using a debit/credit card may incur a small processing fee. Details of any fees will be shown before you complete the transaction.",
      },
      {
        question: "Bow long does it take for the money to appear in my account?",
        answer: "Bank transfers typically take 1-3 business days to process.Adding money via debit/credit card is usually instant. As for cash deposits they are around 30 minutes to 1 hour (throughout office hours)",
      },
      {
        question: "What is the maximum amount i can add to my rival account?",
        answer: "The maximum amount you can add depends on your account verification level. Please refer to the 'Limits' section in your app for specific details",
      },
    ],
    desc1: "How do i add money to my rival account?",
    desc2: "Are there any fees fo adding money to my rival account?",
    desc3: "How long does it take for the money to appear in my account?",
  },
  {
    title: "Transfer",
    // path: "/help&support-details/help3",
    path: "/help&support-details",
    desc1: "how do i add money to my rival account?",
    desc2: "Revolut plans and benefits",
    questions: [
      {
        question: "How do i transfer money to another rival user?",
        answer: "Go to the 'Transfers' section in the app, enter the recipient's phone number, add them as a friend or select them from your contacts, enter the amount, and confirm the transfer",
      }, {
        question: "Can i transfer money to a non-rival user?",
        answer: "Yes, you can transfer money to a non-Rival user by entering their bank account details. The funds will be transferred directly to their bank account. You could also transfer money internationally through our international networks, we will always keep growing our network of partners so keep an eye out on new locations and hotspots",
      },

      {
        question: "Are there any fees for transferring money?",
        answer: "Transfers between Rival users are free. Transferring money to a bank account may incur a small fee, which will be displayed before you confirm the transfer. Other transfers will also incur fees and depending on your plan, will have different fees accordingly",
      },
      {
        question: "How long does it take for a transfer to be completed?",
        answer: "Transfers between Rival users are instant. Bank transfers typically take 1-3 business days to complete. If we have any compliance issues we might freeze your money till we have completed all checks.For more information contact support on support@rival.finance",
      },
      {
        question: "My driver has not contacted me yet?",
        answer: "Our drivers will contact you up to 1 hour after your request has been initiated, if no one has contacted you yet after 1 hour, please contact us through our support chat.",
      },

    ],
    desc1: "How do i transfer money to another rival user?",
    desc2: "Can i transfer money to a non-rival user?",
    desc3: "Are there any fees for transferring money?",
  },
  {
    title: "Card payments",
    path: "/help&support-details",
    // path: "/help&support-details/help3",
    path: "/help&support-details",
    desc1: "Rival's operations and license show do i purchase a rival card",
    questions: [
      {
        question: "Rival's operations and license show do i purchase a rival card",
        answer: "To order a Rival card, go to the 'Cards' section in the app, select a virtual or physical card' and follow the instructions.Your card will be mailed to your registered address.",
      },
    ],
    // desc2: "Revolut plans and benefits",
    // desc3: "Submitting requested documents",
  },
  {
    title: "Card",
    path: "/help&support-details",
    // path: "/help&support-details/help3",
    desc1: "Can i use my rival physical for online purchases?",
    questions: [
      {
        question: "Can i use my rival physical for online purchases?",
        answer: "Yes, your Rival card can be used for online purchases anywhere that accepts card payments. Make sure you have sufficient funds in your account.",
      },
      {
        question: "Are there any fees for using the card?",
        answer: "We do not charge transaction fees for card payments unless the payment is made in a currency other than USD. In that case, an FX fee of 1.3% per transaction will apply, as our accounts are USD-based",
      },
      {
        question: " How do i add money to my card?",
        answer: "After selecting a plan and issuing your card, you will have to wait to see your card active. If it is in the pending status please wait as we validate your card. After your card's activation, you will be able to topup your card from your Current Account. To add money to your card you will go to your 'Card Account' > 'Add Money to Card' > Select Card > Input Amount > Confirm Amount",
      },
      {
        question: "Card is stuck in pending?",
        answer: "Most times our cards are issued instantly after proceeding with getting verified and selecting a plan, sometimes you will see the 'Pending' status next to the card. Don't worry we sometimes have additional checks on some users which is completely normal, these additional checks come from our international card issuer and partnered bank.If the 'Pending' status shows up for more than 30 minutes please contact support through our support chat",
      },
      {
        question: "What should i do if my card payment is declined?",
        answer: "",
      },
      {
        question: "Are there any limits on card payments?",
        answer: "Yes, there are daily and monthly spending limits on your Rival card. You can view and manage these limits in the limits section of the app. Each plan has different limits and different types of cards have different limits, before purchasing a card make sure you have knowledge of these limits.",
      },
      {
        question: "how do i add my card to apple pay?",
        answer: "After issuing your card in the Rival app, there are a few steps that should be followed. Below is a full step by step flow of adding your card to Apple Pay.1. Open the Settings App on your Iphone 2. Press on General3. Select Language & Region4. Change your Region from your selected region to any other country: We recommend the US, UAE or United KingdomNB: (This does not affect any of your phones features nor will it change your App Store region)1. After seeing the Apple Icon, please enter the Rival app once more, press on. Card Details and copy your card details so you will manually add your card to the Apple Wallet.2. Enter the Wallet app and press on the "+" icon (Make sure your Wallet app has Cellular Data ON from the settings app)1. Select Add Debit/Credit card3. Very your pro detain retrievedwaltheR, and you'reready to go! Congrats!",
      },
      {
        question: "How can i dispute a card payment?",
        answer: "If you notice an unauthorised or incorrect charge on your card, contact our support team through the app to initiate a dispute.Some disputes may incur additional charges and our support team will provide further details in the matter",
      },
      {
        question: "What should i do if my card is lost or stolen?",
        answer: "Immediately go to the 'Cards' section in the app and freeze your card to prevent unauthorised use. Then, contact our support team to report the loss and request a replacement card",
      },
    ],
    desc2: "Are there any fees for using the card?",
    desc3: " How do i add money to my card?",
  },
  {
    title: "Subscription and upcoming payment",
    path: "/help&support-details",
    // path: "/help&support-details/help3",
    questions: [
      {
        question: "Can i have multiple cards linked to my rival account?",
        answer: "Yes, you can have multiple cards linked to your Rival account.You can manage all your cards in the 'Cards. The maximum number of cards is 6 for all plans. Card limits are also segregated according to every users plans, for more information check out the different plans in our app or on our website www.rival.finance",
      },
      {
        question: "How do i setup a plan on rival?",
        answer: "To set up a subscription, go to the plans section in the app, choose the service you want to subscribe to, and select your preferred payment frequency (monthly or yearly). Follow the instructions to confirm and complete the setup.",
      },
      {
        question: "What happens if i fail to pay my subscription on time?",
        answer: "If you fail to pay your subscription on time, your account and all its services will be frozen until the payment is made. Additionally your card will be frozen until you complete your payments.",
      },
      {
        question: "Can i cancel my subscription at any time?",
        answer: "Yes, you can cancel your subscription at any time. Go to the 'Subscriptions' section in the app, select the subscription you want to cancel, and follow the prompts to complete the cancellation.",
      },
      {
        question: "What are the payment options for subscriptions?",
        answer: "You can choose to pay for your subscription on a monthly or yearly basis. Monthly subscriptions will be billed every month, while yearly subscriptions will be billed once a year.You could pay your initial payment through card or for users in specific countries through cash.",
      },
      {
        question: "How i can reactivate my account after it has been frozen due to non-payment",
        answer: "To reactivate your account, log in to the Rival app and go to the 'Subscriptions' section. You will need to pay any outstanding subscription fees and the penalty fee to unfreeze your account and resume using Rival's services. Your payment will also include a 3 month in advance payment as if you are subscribing for the first time. For more information please contact support",
      },
      {
        question: "Will i receive reminders about upcoming subscription payments?",
        answer: "Yes, Rival will send you reminders about upcoming subscription payments. You will receive notifications via email and within the app to ensure you are aware of any upcoming charges.You will also receive a receipt to your email after paying your monthly subscription for reference. If there are any issues please contact support",
      },
      {
        question: "Can i change my subscription plan from monthly to yearly(or vice versa)?",
        answer: "Yes, you can change your subscription plan at any time. Go to the Plans section in the app, select your current subscription, and choose the option to switch to a monthly or yearly plan.Unfortunately, if you had paif for the yearly plan, the rest of the year will not be refundable, similarly for the monthly plan if you had paid the initial 3 months and then you wanted to change to a yearly plan, the 3 months are nonrefundable.",
      },
    ],
    desc1: "Can i have multiple cards linked to my rival account?",
    desc2: "How do i setup a plan on rival?",
    desc3: "What happens if i fail to pay my subscription on time?",
  },
  {
    title: "Referrals",
    path: "/help&support-details",
    // path: "/help&support-details/help3",
    desc1: "How do i refer a friend to rival?",
    questions: [
      {
        question: "How do i refer a friend to rival?",
        answer: "To refer a friend to Rival, go to the 'Referrals' section in the app, select 'Invite Friends,' and share your unique referral link with them. When your friend signs up using your link and meets the required conditions, you could earn up to 50$",
      },
      {
        question: "What rewards do i get for referring friends?",
        answer: "Referral rewards vary from time to time. Currently, you and your friend can earn cash bonuses or other perks once they sign up and subscribe. Check the 'Referrals' section in the app for the current rewards you and your friend can earn.",
      },
      {
        question: "Is there a limit to the number of friends i can refer to?",
        answer: "There is no limit on the number of friends you could subscribe to, however you have 100 days from registering your account to invite your friends. So make sure you they complete it early on !",
      },
      {
        question: "How do i refer a friend to rival?",
        answer: "To refer a friend to Rival, go to the 'Referrals' section in the app, select 'Invite Friends,' and share your unique referral link with them. When your friend signs up using your link and meets the required conditions, you will earn up to $50",
      },
      {
        question: "What rewards do i get for referring friends?",
        answer: "Referral rewards vary from time to time. Currently, you and your friend can earn cash bonuses or other perks once they sign up and subscribe. Check the 'Referrals' section in the app for the current rewards you and your friend can earn.",
      },
      {
        question: "Is there a limit to the number of friends i can refer to?",
        answer: "There is no limit on the number of friends you could subscribe to, however you have 100 days from registering your account to invite your friends. So make sure you they complete it early on !",
      },
    ],
    desc2: "What rewards do i get for referring friends?",
    desc3: "Is there a limit to the number of friends i can refer to?",
  },
  {
    title: "Crypto",
    path: "/help&support-details",
    // path: "/help&support-details/help3",
    desc1: "Who could access Rival's cryptocurrency features?",
    questions: [
      {
        question: "Who could access Rival's cryptocurrency features?",
        answer: "Rival does not directly operate with cryptocurrencies, we are a technology company that faciliates all sorts of payments including cryptocurrencies with third parties. For more information please check out our Cryptocurrency Terms & Conditions.For more questions you could also contact legal@rival.finance",
      },
      {
        question: "What are the cryptocurrencies allowed?",
        answer: "Currently, Rival allows only USDT deposits using the tron (TRC20) and Ethreum (ERC20) blockchains. We are looking forward to onboarding new blockchains, tokens and coins very soon.",
      },
    ],
    desc2: "What are the cryptocurrencies allowed?",
    // desc3: "Submitting requested documents",
  },
];

const NeedHelp = () => {
  const navigate = useNavigate();
  const { handlePopoverClose } = useContext(MyContext);
  return (
    <div style={{ background: "#f5f5f7" }}>
      <div className={classes.need_hand} onMouseEnter={handlePopoverClose}>
        <h1>Need a hand?</h1>
        <p>Just pop your question below to get an answer.</p>
        <div className={classes.need_help_input}>
          <input type="text" placeholder="Tell us what you're looking for" />
          <AiOutlineSearch size={24} />
        </div>
        <div className={classes.need_hand_child}>
          {data?.map((item, index) => (
            <NeedHandChild
              key={index}
              title={item.title}
              question={item?.questions}
              // description={item.description}
              desc1={item.desc1}
              desc2={item.desc2}
              desc3={item.desc3}
              path={item.path}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NeedHelp;
