import React, { useContext } from 'react';
import { MyContext } from '../../Context/AuthContext';
import "./Transfer.css";

import Component12 from '../../build-component/component12/Component12';
import Component13 from "../../build-component/component13/Component13";
import Component14 from "../../build-component/component14/Component14";
import Component15 from "../../build-component/component15/Component15";
import Component9 from "../../build-component/component9/Component9";
import Component4 from "../../build-component/component4/Component4";
import Toggle from '../../components/toggle/Toggle';
import Component16 from '../../build-component/component16/Component16';
import Component25 from '../../build-component/component25/Component25';



const Transfer = () => {
  const {
    handlePopoverClose,
  } = useContext(MyContext);

  return (
    <div onMouseEnter={handlePopoverClose}>
      <Component25 />
      {/* <Component12 /> */}
      <Component13 />
      {/* <Component14 /> */}
      <Component4 />
      <Component15 />
      <Component9 />
      <Component16 />
      <Toggle />
    </div>
  );
};

export default Transfer;
