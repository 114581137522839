import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import Personal from "./pages/personal/Personal";
import Savings from "./pages/savings/Savings";
import Cards from "./pages/cards/Cards";
import Transfer from "./pages/transfer/Transfer";
import Investing from "./pages/investing/Investing";
import ComparePlans from "./pages/compare-plans/ComparePlans";
import Crypto from "./pages/crypto/Crypto";
import NeedHand from "./pages/need-hand/NeedHand";
import HelpSupportMain from "./build-component/help-support-section/help-support-main/HelpSupportMain";
import AboutUs from "./pages/about-us/AboutUs";
import Terms from "./pages/terms/Terms";
import RivalTerms from "./build-component/terms/rival-terms/RivalTerms";
import PartnersTerms from './build-component/terms/partners-terms/PartnersTerms';
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";

import Help1 from "./build-component/help-support-section/Help1";
import Help2 from "./build-component/help-support-section/Help2";
import Help3 from "./build-component/help-support-section/Help3";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Personal" element={<Personal />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="transfer" element={<Transfer />} />
          <Route path="compare" element={<ComparePlans />} />
          <Route path="crypto" element={<Crypto />} />
          <Route path="investing" element={<Investing />} />
          <Route path="aboutUs" element={<AboutUs />} />
          <Route path="help&support" element={<NeedHand />} />
          <Route path="help&support-details" element={<HelpSupportMain />}>
            {/* <Route index element={<Help1 />} />
            <Route path="help2" element={<Help2 />} />
            <Route path="help3" element={<Help3 />} /> */}
          </Route>
          <Route path="terms&conditions" element={<Terms  />} />
          <Route path="rival-terms" element={<RivalTerms />} />
          <Route path="partners-terms" element={<PartnersTerms />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
};
export default App;

// import React from 'react'
// import { useLocation } from 'react-router-dom';

// import Home from './pages/home/Home';
// import Personal from './pages/personal/Personal';
// import Savings from './pages/savings/Savings'
// import Cards from './pages/cards/Cards';
// import Transfer from './pages/transfer/Transfer';
// import Investing from './pages/investing/Investing';
// import ComparePlans from './pages/compare-plans/ComparePlans';
// import Crypto from './pages/crypto/Crypto';
// import NeedHand from './pages/need-hand/NeedHand'
// import AboutUs from './pages/about-us/AboutUs';

// import Header from './components/header/Header';
// import Footer from './components/footer/Footer'

// const App = () => {
//   const location = useLocation();

//   const getPageComponent = () => {
//     const searchParams = new URLSearchParams(location.search);
//     const pageId = searchParams.get('page_id');

//     switch (pageId) {
//       case '1':
//         return <Personal />;
//       case '2':
//         return <Savings />;
//       case '3':
//         return <Cards />;
//         case '4':
//           return <Transfer />;
//         case '5':
//           return <Investing />;;
//         case '6':
//           return <ComparePlans />;
//         case '7':
//           return <Crypto />;
//         case '8':
//           return <NeedHand />;
//         case '9':
//           return <AboutUs />;
//       default:
//         return <Home />;
//     }
//   }
//   return (
//     <React.Fragment>
//       <Header />
//       {getPageComponent()}
//       <Footer />
//     </React.Fragment>
//   )
// }

// export default App;
